<template>
  <van-dialog
    :width="'50%'"
    :closeOnClickOverlay="false"
    :beforeClose="closed"
    :overlayClass="'mapDialog'"
    :overlay="visible"
    class="dialog-container">
    <span slot="title" class="dialog-title">
      <div class="dialog-before"></div>
      <span style="margin-left:12px">坐标拾取器</span>
    </span>
    <div id="map" style="position: relative;">
      <van-search
          v-model="search"
          placeholder="请输入搜索的内容"
          @change="mapSearch()"
          style="position: absolute;right:0;top:0;width: 200px;height:50px;margin-right: 1px;background-color: #cccccc"/>
    </div>
  </van-dialog>
</template>

<script>
  import L from 'leaflet'
  import 'leaflet.chinesetmsproviders'
  import 'leaflet/dist/leaflet.css'

  var gaodeGeoCodeAPI = 'https://restapi.amap.com/v3/place/text'
  var gaodeAPIKey = '83d81b7a2929edbfe46417c8d045c9fe'
  let tianDiTuAPI = 'http://api.tianditu.gov.cn/v2/search'
  let tianDiTuKey = '9094e0b6dbedd9d95ab8226da17e1378'
  export default {
    name: 'LngLat',
    data () {
      return {
        orgId: 0,
        visible: false,
        lng: 120.1489,
        lat: 30.2927,
        depth: 15,
        map: null,
        search: '',
        isMap: false,
        markerDrag: null,
      }
    },
    methods: {
      // 机构id，初始化时需要以哪个组织机构的坐标为中心
      init (orgId, isMap) {
        this.isMap = isMap || ''
        this.visible = true
        this.orgId = orgId
        if (this.$location.longitude > 0) {
          this.lng = this.$location.longitude
          this.lat = this.$location.latitude
        }
        if (this.orgId > 0) {
          this.initMap()
        }
        document.getElementById('map').style.display = 'block'
      },
      // 获取当前机构的坐标点作为地图的中心点
      getOrg () {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/sys/org/extended?orgId=` + this.orgId),
          method: 'get',
          data: {}
        }).then(({data}) => {
          if (data && data.code === 0) {
            if (data.extendedMap.lng > 0) {
              this.lng = data.extendedMap.lng
              this.lat = data.extendedMap.lat
            }
            // this.depth = data.sysOrg.depth
            this.initMap()
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      // 地图初始化
      initMap () {
        if (!this.map) {
          // // 根据机构的层级，确定地图的放大级别
          this.markerDrag = null;
          var zoom = 18
          if (this.depth === 15) {
            zoom = 18
          }

          let map = L.map('map', {
            minZoom: 12,
            maxZoom: 18,
            center: [this.lat, this.lng],
            zoom: zoom
          })

          L.tileLayer.chinaProvider('GaoDe.Normal.Map', {
            maxZoom: 18,
            minZoom: 12
          }).addTo(map)

          this.map = map

          var greenIcon = L.icon({
            iconUrl: require('@/assets/img/poi.png'),
            iconSize: [24, 24], // 图标的大小
            iconAnchor: [0, -4], // 图标将对应标记点的位置
            popupAnchor: [0, 0] // 该点是相对于iconAnchor弹出信息的位置
          })

          // this和dblclick中的this有冲突
          var that = this
          var marker = L.marker([this.lat, this.lng],{
            icon: greenIcon,
            draggable:true,
            autoPan:true
          }).openTooltip().on({
            'dragend': function (e) {
              this.markerDrag = e.target.getLatLng()
              that.map.remove()
              document.getElementById('map').style.display = 'none'
              that.$emit('closed', {lng: this.markerDrag.lng.toFixed(7), lat: this.markerDrag.lat.toFixed(7)})
            }
          }).addTo(map);
          map._onResize();

          // this和dblclick中的this有冲突
          // var that = this
          // map.off('dblclick').on('dblclick', function (e) {
          //   that.map.remove()
          //   document.getElementById('map').style.display = 'none'
          //   // 双击得到坐标，关闭当前dialog
          //   that.$emit('closed', {lng: e.latlng.lng.toFixed(7), lat: e.latlng.lat.toFixed(7)})
          // })
        } else {
          this.map.panTo(new L.LatLng(this.lat, this.lng))
        }
      },
      /**
       * 地图搜索
       */
      mapSearch () {
        if (this.map != null) {
          // 根据高德api搜索
          this.$jsonp(tianDiTuAPI + '?key=' + tianDiTuKey + '&keywords=' + this.search).then(json => {
            if (parseInt(json.status) === 1) {
              if (json.pois.length > 0) {
                var location = json.pois[0].location
                var lnglat = location.split(',')
                this.map.panTo(new L.LatLng(lnglat[1], lnglat[0]))
              }
            }
          })
        }
      },
      // 窗口关闭
      closed (latlng) {
        this.visible = false
        // 回调父组件的方法
        this.$emit('closed', latlng)
      }
    }
  }
</script>

<style>
  #map {
    width:100%;
    height:400px;
  }
  .mapDialog .el-dialog__body{
    padding: 0 !important;
  }
</style>
